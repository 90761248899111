<template>
  <modal name="delete-user-modal" @before-open="beforeOpen" @closed="clear" :adaptive="true" height="auto" :maxWidth="438"
         class="modal-delete-user modal">
    <h3 class="modal-title">Are you sure?</h3>
    <div class="modal-delete-buttons df">
      <button class="btn blue" @click="close">Cancel</button>
      <button class="btn red" @click="confirmDelete">Delete</button>
    </div>
  </modal>
</template>

<script>
import modal from "@/mixins/modal";
import {mapActions, mapGetters} from "vuex";
import {eventBus} from '@/main';

export default {
  name: "UserDeleteModal",
  mixins: [modal],
  computed: {
    ...mapGetters(['selectedUser'])
  },
  methods: {
    ...mapActions(['setTempUser', 'deleteUser']),
    clear() {
      this.setTempUser(null);
      this.modalActive = false;
    },
    close(){
      this.$modal.hide('delete-user-modal');
    },
    async confirmDelete(){
      await this.deleteUser(this.selectedUser.id).then(() => {
        eventBus.$emit('onLoadUsers');
        this.$notify({
          title: 'Delete user',
          text: 'Success deleting user!',
          type: 'success'
        });
      }).catch(error => {
        console.error('Error deleting user:', error);
        this.$notify({
          title: 'Delete user',
          text: error.response.data.message,
          type: 'error'
        });
      });
      this.close();
    },
  },
}
</script>

<style scoped>

</style>
